




























































































































import { Vue, Component } from "vue-property-decorator";
import { organisationModule } from "@/store/modules/organisation";

import ActivityChart from "../components/comparison/ActivityGraph.vue";
import AccumulatedChart from "../components/overview/AccumulatedChart.vue";
import StackedBarChart from "../components/municipal/StackedBarChart.vue";
import YearlyChart from "../components/overview/YearlyChart.vue";
import DonutChart from "../components/municipal/DonutChart.vue";
import AreasBarChart from "../components/municipal/AreasBarChart.vue";
import PieChart from "../components/details/PieChart.vue";
import ColumnChart from "../components/details/ColumnChart.vue";

import OrganizationDetails from "../components/details/OrganizationDetails.vue";
import ActivityDetails from "../components/details/ActivityDetails.vue";

import Dropdown from "../components/shared/Dropdown.vue";
import FilterBar from "../components/shared/FilterBar.vue";

import CarIcon from "vue-material-design-icons/Car.vue";
import TrainIcon from "vue-material-design-icons/Train.vue";
import AirplaneIcon from "vue-material-design-icons/Airplane.vue";
import HumanGreetingIcon from "vue-material-design-icons/HumanGreeting.vue";
import InfoIcon from "vue-material-design-icons/InformationOutline.vue";

@Component({
  components: {
    CarIcon,
    TrainIcon,
    AirplaneIcon,
    InfoIcon,
    HumanGreetingIcon,
    OrganizationDetails,
    ActivityDetails,
    AccumulatedChart,
    ActivityChart,
    YearlyChart,
    Dropdown,
    FilterBar,
    StackedBarChart,
    DonutChart,
    PieChart,
    ColumnChart,
    AreasBarChart,
  },
})
export default class StartView extends Vue {
  get organisation() {
    return organisationModule;
  }

  async selectYear(year) {
    await this.organisation.setYear(year);
  }
}
