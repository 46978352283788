










import { Vue, Component, Prop } from "vue-property-decorator";
import VueApexCharts from "vue-apexcharts";
import defaultChartOptions from "@/models/defaultChartOptions";
import { organisationModule } from "@/store/modules/organisation";
import Helper from "../../utils/helper";
import { ApexOptions } from "apexcharts";
import _ from "lodash";

@Component({
  components: {
    apexchart: VueApexCharts,
  },
})
export default class YearlyChart extends Vue {
  @Prop({ default: "Utsläpp per år" }) readonly chartTitle!: string;
  @Prop({ default: "left" }) readonly chartTitleAlign!:
    | "left"
    | "center"
    | "right";
  @Prop({ default: "kg" }) readonly unit!: string;

  public get organisation() {
    return organisationModule;
  }

  public get options() {
    const options: ApexOptions = {
      plotOptions: {
        bar: {
          dataLabels: {
            position: "top",
          },
        },
      },
      dataLabels: {
        offsetY: -25,
        formatter: (_, { seriesIndex, dataPointIndex, config }) => {
          const dataPoint = config.series[seriesIndex].data[dataPointIndex];
          const formatted = new Intl.NumberFormat("sv-SE", {
            minimumFractionDigits: 0,
            maximumFractionDigits: 1,
          }).format(dataPoint);
          return formatted + " ton";
        },
      },
      tooltip: {
        y: {
          formatter: (value) => {
            const formatted = new Intl.NumberFormat("sv-SE", {
              minimumFractionDigits: 0,
              maximumFractionDigits: 1,
            }).format(value);
            return formatted + " ton CO<sub>2</sub";
          },
        },
      },
      yaxis: {
        title: {
          text: `CO\u2082 (${this.unit})`,
        },
        decimalsInFloat: 0,
      },
      title: {
        align: this.chartTitleAlign,
        text: this.chartTitle,
      },
      xaxis: {
        categories: this.organisation.getCo2PerYear.map((x) => x.year),
      },
      colors: this.organisation.getCo2PerYear.map(() => "#fac228"),
    };

    return _.merge({}, defaultChartOptions, options);
  }

  public get series() {
    const series = [
      {
        name: "Totalt",
        data: this.organisation.getCo2PerYear.map((x) =>
          Helper.round(x.co2 / 1000)
        ),
      },
    ];
    return series;
  }
}
